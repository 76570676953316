import { AuthenticationStrategy, AuthenticationResult } from './authentication-strategy.interface';
import { environment } from '../../environments/environment';

interface Module {
  moduleId: string;
  moduleName: string;
}

const modules: Module[] = [
  { moduleId: "CA", moduleName: "營造業甲種職業安全衛生業務主管" },
  { moduleId: "F", moduleName: "有機溶劑作業主管" },
];


export class TpicAuthenticationStrategy implements AuthenticationStrategy {


  private accounts = [
    { username: 'tpic', password: 'tpic', modules: ['ALL'] },
    // 可以在這裡繼續增加更多帳號、密碼及模組
  ];

  async authenticate(credentials: { username: string; password: string }): Promise<AuthenticationResult> {


    // 檢查帳號和密碼是否匹配
    const account = this.accounts.find(acc => acc.username === credentials.username && acc.password === credentials.password);

    if (account) {
      // 如果找到匹配的帳號和密碼，回傳成功並帶有對應的模組

      return {
        success: true,
        username: credentials.username,
        modules: account.modules,
      };
    } else {
      try {
        // 呼叫 Azure Function 驗證
        const response = await fetch(
          `https://yuhsinefunctions.azurewebsites.net/api/Login?code=7TfeFpRT3foa0sGTD3J/l0Q0Cnwy//3J4JDPXWmfRaaaJ7xa6PV9FA==&id=${credentials.username}&pw=${credentials.password}`
        );
        const data = await response.json();

        // 若回傳空陣列，代表帳號密碼不存在
        if (!Array.isArray(data) || data.length === 0) {
          return {
            success: false,
          };
        }

        // 若回傳的有值
        // 取第一筆的 Name 當 username
        const username = data[0].Name;

        // 將所有筆資料的 MNo 收集成陣列，可視需要去重 (new Set) 或過濾 null
        const modulesMNo = data.map((item: any) => item.MNo).filter((mNo: string) => mNo);

        const moduleNames = modulesMNo.map(mNo => {
          // 在 modules 陣列中找出與 mNo 相符的 moduleId
          const module = modules.find(item => item.moduleId === mNo);
          return module ? module.moduleName : null;  // 若找不到對應的 moduleId, 回傳 null
        }).filter(name => name !== null);  // 過濾掉 null

        // 這裡你可以修改為從模組名稱來設定正確的權限
        const accessibleModules = moduleNames.length ? moduleNames : [""];

        return {
          success: true,
          username,
          modules: accessibleModules,
        };
      } catch (error) {
        console.error('Login API error:', error);
        return {
          success: false,
        };
      }
    }
  }

}
