<div class="component-container">
  <h3>堆高機每日工作總檢表</h3>
  <div *ngIf="formfinished">表單填寫完成!</div>
  <div *ngIf="!formfinished">
    <form (ngSubmit)="sendValue()">
      <div class="form-section" *ngFor="let item of checkItems">
        <label>{{ item.title }}</label>

        <div class="radio-group">
          <label>
            <input
              type="radio"
              name="{{ item.name }}"
              [(ngModel)]="item.checked"
              [value]="'yes'"
              (change)="item.remarks = ''"
            /> 是
          </label>
          <label>
            <input
              type="radio"
              name="{{ item.name }}"
              [(ngModel)]="item.checked"
              [value]="'no'"
              (change)="item.remarks = ''"
            /> 否
          </label>
        </div>

        <div *ngIf="item.checked === 'no'" class="remarks-section">
          <label for="{{ item.name }}-remarks">備註</label>
          <textarea
            id="{{ item.name }}-remarks"
            [(ngModel)]="item.remarks"
            name="{{ item.name }}-remarks"
            placeholder="請填寫備註"
          ></textarea>
        </div>
      </div>

      <button type="submit">提交</button>
    </form>
  </div>
</div>
