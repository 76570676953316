
<div *ngIf="lobbyinfotext != ''" class="lobby-info" [innerHTML]="lobbyinfotext">

</div>

<div class="row row-cols-1 row-cols-md-3 g-4">
  <div class="col" *ngFor="let item of AssistantDataItem">
    <a
      (click)="(item.enable) && gotoAI(item.assistantNo)"
      target="_blank"
      style="cursor: pointer;"
      class="text-decoration-none"
      [class.disabled]="!item.enable"
      [style.pointer-events]="item.enable ? 'auto' : 'none'">
      <div class="card" [class.card-disabled]="!item.enable">
        <img *ngIf="item.imageurl" [src]="item.imageurl" class="card-img-top" [alt]="item.title">
        <div *ngIf="!item.imageurl" class="card-header text-center">
          <span class="card-title-text">{{ item.title }}</span>
        </div>
        <div class="card-body">
          <h5 class="card-title">{{ item.title }}</h5>
          <p class="card-text">{{ item.content }}</p>
        </div>
        <div class="card-footer">
          <small class="text-muted" [innerHTML]="item.memo"></small><br>
        </div>
      </div>
    </a>
  </div>
</div>
