import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-widgetdemo',
  standalone: true,
  imports: [],
  templateUrl: './widgetdemo.component.html',
  styleUrls: ['./widgetdemo.component.scss']
})
export class WidgetdemoComponent implements OnInit {

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    console.log('lobby component loaded');

    // 加載 marked.js CDN
    const markedScript = this.renderer.createElement('script');
    markedScript.src = "https://cdn.jsdelivr.net/npm/marked@4.0.12/marked.min.js";  // 載入 CDN
    markedScript.type = "text/javascript";
    markedScript.async = true;
    markedScript.onload = () => {
      console.log('marked.js loaded');
      this.loadChatWidget();  // 確保 marked.js 加載完成後再加載 chat-widget.js
    };
    markedScript.onerror = () => {
      console.error('Failed to load marked.js. Please check the CDN URL.');
    };
    this.renderer.appendChild(document.body, markedScript);
  }

  // 加載 chat-widget.js
  loadChatWidget() {
    const script = this.renderer.createElement('script');
    script.src = 'assets/widget/chat-widget.js';
    script.type = 'text/javascript';
    script.async = true;
    this.renderer.appendChild(document.body, script);

    console.log('chat-widget loaded');
  }
}
