<div *ngFor="let file of fileInfos; let i = index" class="file-info">


  <h4>File {{ i + 1 }}</h4>

  <div>
    <strong>File Name:</strong> {{ file.name }}
  </div>

  <div>
    <strong>Status:</strong>
    <span [ngClass]="{'green-light': file.status === 'Y', 'red-light': file.status === 'N'}">
      {{ file.status === 'Y' ? 'Available' : 'Not Found' }}
    </span>
  </div>

  <div>
    <strong>Size:</strong> {{ file.size }}
  </div>

  <div>
    <strong>Created On:</strong> {{ file.createdOn }}
  </div>

  <div class="button-set">
    <button (click)="downloadBlobFile(file.name)" [disabled]="file.status !== 'Y'">Download</button>
    <button (click)="preview(file.name)" [disabled]="file.status !== 'Y'">Preview</button>
  </div>

  <hr />
</div>

<!-- <div id="fileinfo">
  <div>
    <strong>File Status:</strong>
    <span [ngClass]="{'green-light': fileStatus === 'Y', 'red-light': fileStatus === 'N'}"></span>
  </div>
  <div><strong>File Name:</strong> {{ fileName }}</div>
  <div><strong>File Size:</strong> {{ filesize }}</div>
  <div><strong>File Version:</strong> 1 </div>
  <div><strong>Created Time:</strong> {{ createdOn }}</div>
</div>

<div id="buttonset">
<button (click)="downloadBlobFile()">Download</button>
<button (click)="preview()">Preview</button>
</div>
 -->
