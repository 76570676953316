import { AuthenticationStrategy, AuthenticationResult } from './authentication-strategy.interface';
import { environment } from '../../environments/environment';

export class QuallicoAuthenticationStrategy implements AuthenticationStrategy {

  accounts = [
    { username: 'lico', password: 'lico', modules: ['ALL'] },
    { username: 'nicole', password: 'nicole888', modules: ['EHS Compliance AI Assistant','ERT Support AI Assistant'] },
    { username: 'chia', password: 'chia888', modules: ['ERT Support AI Assistant'] },
    // 可以在這裡繼續增加更多帳號、密碼及模組
  ];

  async authenticate(credentials: { username: string; password: string }): Promise<AuthenticationResult> {
    // 檢查帳號和密碼是否匹配
    const account = this.accounts.find(acc => acc.username === credentials.username && acc.password === credentials.password);

    if (account) {
      // 如果找到匹配的帳號和密碼，回傳成功並帶有對應的模組

      return {
        success: true,
        username: credentials.username,
        modules: account.modules,
      };
    } else {
      // 如果未找到匹配，回傳失敗
      return {
        success: false,
      };
    }
  }
}
