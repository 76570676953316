import { Component } from '@angular/core';

@Component({
  selector: 'app-questions-formatter',
  standalone: true,
  imports: [],
  templateUrl: './questions-formatter.component.html',
  styleUrls: ['./questions-formatter.component.scss'],
})
export class QuestionsFormatterComponent {
  selectedFile: File | null = null;
  uploadStatus: string = '';
  private SbjectId = "有機溶劑作業主管"; // 固定的科目名稱
  private fileName: string = '';
  private optimizedQuestions: any[] = []; // 儲存優化後的題目資料

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.selectedFile = input.files[0];
      this.fileName = this.selectedFile.name.replace('.json', '') || 'fixed'; // 獲取原檔案名稱
    }
  }

  // 優化題目資料，並加入 id 和 SbjectId 欄位
  optimizeQuestionData(question: any, index: number): any {

    return {
      id: `q${index + 1}`, // 根據索引自動生成 id
      SbjectId: this.SbjectId, // 使用固定的科目名稱
      correctAnswerIndex: question.correctAnswerIndex,
      text: question.text.trim(), // 去除多餘的空白
      options: question.options.map((option: string) => option.trim()), // 去除選項的多餘空白
    };
  }

  // 過濾掉選項數量少於 4 的題目
  filterQuestions(questions: any[]): any[] {
    return questions.filter((question) => question.options && question.options.length >= 4);
  }

  // 處理檔案上傳並優化題目
  async handleFileUpload(): Promise<void> {
    if (!this.selectedFile) {
      this.uploadStatus = '請選擇一個檔案';
      return;
    }

    const reader = new FileReader();
    reader.onload = async () => {
      try {
        const jsonData = JSON.parse(reader.result as string);

        jsonData.sort((a: any, b: any) => a.id - b.id);

        // 優化每一道題目，並加入 id 和 SbjectId
        const optimizedQuestions = jsonData.map((question: any, index: number) => this.optimizeQuestionData(question, index));

        // // 過濾掉選項數量少於 4 的題目
        this.optimizedQuestions = this.filterQuestions(optimizedQuestions);

        // 顯示處理結果狀態
        this.uploadStatus = `處理成功，共 ${this.optimizedQuestions.length} 題\n\n` +
        JSON.stringify(this.optimizedQuestions, null, 2);

      } catch (error) {
        this.uploadStatus = '處理檔案時發生錯誤';
        console.error('Error uploading questions:', error);
      }
    };
    reader.onerror = () => {
      this.uploadStatus = '檔案讀取失敗';
    };

    reader.readAsText(this.selectedFile);
  }

  // 下載修正後的 JSON 檔案
  downloadFixedFile(): void {
    if (this.optimizedQuestions.length === 0) {
      this.uploadStatus = '請先處理檔案後再下載';
      return;
    }

    const blob = new Blob([JSON.stringify(this.optimizedQuestions, null, 2)], {
      type: 'application/json',
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${this.fileName}(Fix).json`;
    link.click();
  }
}
