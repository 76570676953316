<div class="component-container">
  <h3>Add Action</h3>
  <div *ngIf="formFinished">Form submission completed!</div>
  <div *ngIf="!formFinished">
    <form (ngSubmit)="sendValue()">
      <div class="form-section">
        <label for="action-id">Action ID</label>
        <input
          type="text"
          id="action-id"
          [(ngModel)]="formData.actionId"
          name="action-id"
          placeholder="Enter Action ID"
        />
      </div>

      <div class="form-section">
        <label for="action-type">Action Type</label>
        <select
          id="action-type"
          name="action-type"
          [(ngModel)]="formData.actionType"
          class="form-input"
        >
          <option value="" disabled selected>Please select Action Type</option>
          <option *ngFor="let type of actionTypes" [value]="type">{{ type }}</option>
        </select>
      </div>

      <div class="form-section">
        <label for="date-raised">Date Raised</label>
        <input
          type="date"
          id="date-raised"
          [(ngModel)]="formData.dateRaised"
          name="date-raised"
        />
      </div>

      <div class="form-section">
        <label for="due-date">Due Date</label>
        <input
          type="date"
          id="due-date"
          [(ngModel)]="formData.dueDate"
          name="due-date"
        />
      </div>

      <div class="form-section">
        <label for="action-owner">Action Owner</label>
        <input
          type="text"
          id="action-owner"
          [(ngModel)]="formData.actionOwner"
          name="action-owner"
          placeholder="Enter Action Owner"
        />
      </div>

      <div class="form-section">
        <label for="source">Source</label>
        <select
          id="source"
          name="source"
          [(ngModel)]="formData.source"
          class="form-input"
        >
          <option value="" disabled selected>Please select Source</option>
          <option *ngFor="let src of sourceOptions" [value]="src">{{ src }}</option>
        </select>
      </div>

      <div class="form-section">
        <label for="status">Status</label>
        <select
          id="status"
          name="status"
          [(ngModel)]="formData.status"
          class="form-input"
        >
          <option value="" disabled selected>Please select Status</option>
          <option *ngFor="let stat of statusOptions" [value]="stat">{{ stat }}</option>
        </select>
      </div>

      <div class="form-section" *ngIf="isCapaAction()">
        <label for="immediate-action">Immediate Action</label>
        <textarea
          id="immediate-action"
          [(ngModel)]="formData.immediateAction"
          name="immediate-action"
          placeholder="Enter Immediate Action"
        ></textarea>
      </div>

      <div class="form-section" *ngIf="isCapaAction()">
        <label for="proposed-corrective-action">Proposed Corrective Action</label>
        <textarea
          id="proposed-corrective-action"
          [(ngModel)]="formData.proposedCorrectiveAction"
          name="proposed-corrective-action"
          placeholder="Enter Proposed Corrective Action"
        ></textarea>
      </div>

      <div class="form-section" *ngIf="isCapaAction()">
        <label for="corrective-action">Corrective Action</label>
        <textarea
          id="corrective-action"
          [(ngModel)]="formData.correctiveAction"
          name="corrective-action"
          placeholder="Enter Corrective Action"
        ></textarea>
      </div>

      <div class="form-section" *ngIf="isCapaAction()">
        <label for="proposed-preventative-action">Proposed Preventative Action</label>
        <textarea
          id="proposed-preventative-action"
          [(ngModel)]="formData.proposedPreventiveAction"
          name="proposed-preventative-action"
          placeholder="Enter Proposed Preventative Action"
        ></textarea>
      </div>

      <div class="form-section" *ngIf="isCapaAction()">
        <label for="preventative-action">Preventative Action</label>
        <textarea
          id="preventative-action"
          [(ngModel)]="formData.preventiveAction"
          name="preventative-action"
          placeholder="Enter Preventative Action"
        ></textarea>
      </div>

      <div class="form-section" *ngIf="isObservationAction()">
        <label for="proposed-action">Proposed Action</label>
        <textarea
          id="proposed-action"
          [(ngModel)]="formData.proposedAction"
          name="proposed-action"
          placeholder="Enter Proposed Action"
        ></textarea>
      </div>

      <div class="form-section" *ngIf="isObservationAction()">
        <label for="action-taken">Action Taken</label>
        <textarea
          id="action-taken"
          [(ngModel)]="formData.actionTaken"
          name="action-taken"
          placeholder="Enter Action Taken"
        ></textarea>
      </div>

      <div class="form-section">
        <label for="evidence">Evidence (Upload file)</label>
        <input
          type="file"
          id="evidence"
          name="evidence"
          (change)="handleFileUpload($event)"
          accept="*/*"
          multiple
        />
      </div>

      <div class="form-section">
        <label for="action-comments">Comments</label>
        <textarea
          id="action-comments"
          [(ngModel)]="formData.comments"
          name="action-comments"
          placeholder="Enter your comments"
        ></textarea>
      </div>

      <button type="submit">Submit</button>
    </form>
  </div>
</div>
