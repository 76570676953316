import { Component, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-actions',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
})
export class ActionsComponent {
  @Output() valueSubmitted = new EventEmitter<any>();

  formFinished = false;

  // Form data
  formData = {
    actionId: '',
    actionType: '',
    dateRaised: '',
    dueDate: '',
    actionOwner: '',
    source: '',
    status: '',
    comments: '',
    evidence: [] as File[],

    immediateAction: '',
    proposedCorrectiveAction: '',
    correctiveAction: '',
    proposedPreventiveAction: '',
    preventiveAction: '',
    proposedAction: '',
    actionTaken: '',
  };

  // Action types and options
  actionTypes = ['CAPA', 'Observations', 'Tasks'];
  sourceOptions = ['Internal', 'External'];
  statusOptions = ['Draft', 'Open', 'In Progress', 'Completed', 'Closed'];

  // Check if Action Type is CAPA
  isCapaAction(): boolean {
    return this.formData.actionType === 'CAPA';
  }

  // Check if Action Type is Observations/Tasks
  isObservationAction(): boolean {
    return this.formData.actionType === 'Observations' || this.formData.actionType === 'Tasks';
  }

  // Handle file upload
  handleFileUpload(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const files = Array.from(input.files);
      this.formData.evidence.push(...files);
    }
  }

  // Submit the form
  sendValue() {
    this.valueSubmitted.emit(this.formData);
    this.formFinished = true;
  }
}
