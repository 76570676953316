import {
  Component,
  ChangeDetectorRef,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ComponentRef,
  ViewChildren,
  QueryList,
  viewChild,
  inject,
  ChangeDetectionStrategy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { OpanaiService } from '../../services/openai.service';
import { MarkdownViewerComponent } from '../../shared/markdown-viewer/markdown-viewer.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  DataService,
  AssistantDataItem,
  InitializedItem,
  InitMenuItem,
} from '../../services/data.service';
import { Router, RouterModule } from '@angular/router';

import { environment } from '../../environments/environment';

import { Renderer2, ElementRef, AfterViewInit } from '@angular/core';

import { COMPONENT_MAP } from '../../conponents/component-map'; //元件的對應表
import { ignoreElements } from 'rxjs';

import { AzureBlobService } from '../../services/azure-blob.service';

import jsQR from 'jsqr'; // 使用 jsQR 來解析 QR Code

interface Button {
  icon?: string;       // 按鈕的圖標
  text?:string;        // 按鈕的文字
  action?: () => void; // 按鈕的點擊行為
  tooltip?: string;    // 提示文字
}

@Component({
  selector: 'app-assistant',
  standalone: true,
  imports: [CommonModule, FormsModule, MarkdownViewerComponent],
  templateUrl: './assistant.component.html',
  styleUrl: './assistant.component.scss',
})
export class AssistantComponent implements OnInit {
  showintroduction = true;
  showchat = true;
  showrecommentlist = true;

  AssistantDataItem: AssistantDataItem | null = null;
  Recommendbuttonlist: { title: string; content: string }[] = [];

  //要先判斷是跟一般模型對話還是跟assistant對話
  chattarget = ''; //assistant or model

  modelname = '';
  assistantNo = ''; //AI助手的編號
  assistantid = ''; //AI助手的編號(Key)
  companyname = ''; //公司名稱
  vector_store_id = '';

  containerName = 'quallico'; //blob的container名稱

  init_image = '';
  init_word = '';
  init_meun: InitMenuItem[] = [];
  chatavator = '';
  selectedIcon: string | null = null;
  slogan = '';

  threadid = '';

  selectedFiles: File[] = [];
  imagePreviews: { url: string; uploading: boolean; progress: number }[] = [];

  tools_fileupload = false;
  tools_qrcode = false;

  //動態載入Component用
  @ViewChildren('dynamicComponentContainers', { read: ViewContainerRef })
  dynamicComponentContainers!: QueryList<ViewContainerRef>;

  componentRefs: ComponentRef<any>[] = [];
  cdr = inject(ChangeDetectorRef);

  userMessage!: string;
  assistantReply!: string;

  chatMessages: {
    role: string;
    content: string ;
    component?: string | null;
    images?: string[];
  }[] = [];

  isSendingMessage = false;

  meunbuttons: Button[] = []; // 明確設定型別為 Button[]
  actionbuttons: Button[] = []; // 明確設定型別為 Button[]

  //關鍵字Mapping表
  componentMapping = [
  { keyword: 'Demo-新增稽核缺失', component: 'demoformcomponent',jsondata:'' },
  { keyword: 'Demo-Add Audit Result', component: 'demoformcomponent',jsondata:'' },
  { keyword: 'Demo-nonconformence', component: 'nonConformanceComponent',jsondata:'' },
  { keyword: 'Demo-Download File', component: 'filedownloader',jsondata:'' },
  { keyword: 'Demo-查看稽核缺失分析圖表', component: 'demoform2component',jsondata:'' },
  { keyword: 'pdf', component: 'pdfHelperComponent' ,jsondata:''},
  { keyword: 'qu', component: 'questionsFormatterComponent' ,jsondata:''},
  { keyword: '模擬測驗', component: 'simulateExamComponent' ,jsondata:''},
  { keyword: 'actions', component: 'actionsComponent' ,jsondata:''},
  // { keyword: '模擬測驗', component: 'simulateExamComponent' ,jsondata:JSON.stringify({
  //   "component": "simulateExamComponent",
  //   "classname":"營造業甲種職業安全衛生業務主管",
  //   "totalminutes": 100,
  //   "testnumbers": 80
  //   })},
];




  constructor(
    private openAiApiService: OpanaiService,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    private el: ElementRef,
    private dataService: DataService,
    private router: Router, // 注入 Router
    private azureBlobService: AzureBlobService
  ) {}

  ngOnInit() {
    this.companyname = environment.companyname;
    this.assistantNo = this.router.routerState.snapshot.root.queryParams['id'];

    if (this.assistantNo == '') {
      //導回到lobby頁面
      this.router.navigate(['/lobby']);
    }

    //取得assistant資料
    //取得recommentbuttonlist
    // 使用 DataService 取得資料
    this.dataService
      .getLobbyDataItemByNo(this.companyname, this.assistantNo)
      .subscribe((items) => {

        this.AssistantDataItem = items;

        this.modelname = this.AssistantDataItem?.title || '';
        this.assistantid = this.AssistantDataItem?.assistantid || '';

        if (this.assistantid == '') {
          alert('error: assistantid is empty. Redirect to lobby page');
          //導回到lobby頁面
          this.router.navigate(['/lobby']);
        } else {

          this.init_image =
            this.AssistantDataItem?.Initialized?.init_image || '';
          this.init_word = this.AssistantDataItem?.Initialized?.init_word || '';
          this.init_meun = this.AssistantDataItem?.Initialized?.init_meun || [];
          this.chatavator =
            this.AssistantDataItem?.Initialized?.chatavator || '';

          this.assistantid = this.AssistantDataItem?.assistantid || '';

          //檢查可以使用那些tools
          var tools  = this.AssistantDataItem?.tools || [];

          if(tools.length>0){
            tools.forEach((tool) => {
              if(tool == 'fileupload'){
                this.tools_fileupload = true;
              }
            });
          }


          if (this.assistantid.startsWith('asst')) {
            this.chattarget = 'assistant';
            this.vector_store_id =
              this.AssistantDataItem?.vector_store_id || '';
          } else {
            this.chattarget = 'model';
          }

        }
      });

    //取得左邊Meun的Button
    this.getsidemenuicon();

  }

  getsidemenuicon(): void {

    if(this.companyname == "Tpic"){
      this.meunbuttons = [
        {
          icon: 'fa-solid fa-house',
          text:'',
          action: () => this.gotolobby(),
          tooltip: '回到首頁',
        },
        {
          icon: 'fa-solid fa-circle-info',
          text:'',
          action: () => this.toggleVisibility(),
          tooltip: '顯示說明文字',
        },
        {
          icon: 'fa-solid fa-magnifying-glass-plus',
          text:'',
          action: () => this.adjustFontSize('increase'),
          tooltip: '放大字體',
        },
        {
          icon: 'fa-solid fa-magnifying-glass-minus',
          text:'',
          action: () => this.adjustFontSize('decrease'),
          tooltip: '縮小字體',
        },
        {
          icon: 'fa-solid fa-trash',
          text:'',
          action: () => this.clearmsg(),
          tooltip: '清除對話訊息',
        },
      ];
    }

  };

  menubuttonclick(title: string, content: string, functionname: string = '') {
    // this.showintroduction = false;
    // this.showchat = true;

    //content有值才顯示在對話視窗
    if (content) {
      //跟AI對話
      this.chatwithAI(title, content, functionname);
    } else {
      console.log('找不到對應的內容');
    }
  }

  chatwithAI(title: string, message?: string, component?: string) {
    if (this.isSendingMessage) return; // 防止重複送出
    if (this.userMessage == '' && title == '') return; // 防止空白訊息

    this.actionbuttons = []; // 清空按鈕

    // console.log(this.userMessage);

    this.isSendingMessage = true; // 開始 Loading 狀態

    // console.log('開始:', this.isSendingMessage);

    if (this.chattarget == 'assistant') {
      this.chatwithassitant(title, message, component);
    } else if (this.chattarget == 'model') {
      this.chatwithmodel(title, message, component);
    } else {
      console.log('找不到對應的對話目標');
    }

    this.imagePreviews = [];
  }

  chatwithassitant(title: string, message?: string, component?: string) {
    // console.log('chatwithassitant');
    // console.log('message:', message);
    //顯示在畫面上的是title
    //送到Chatgpt的是content

    //如果有圖片，先轉到使用一般的model，不能使用助手，但助手的threadid要一起帶過去
    if(this.imagePreviews.length>0){
      this.chatwithmodel(title, message, component,this.threadid);
      return;
    }

    const userComponent = component || '';

    if (title != '') {
      this.chatMessages.push({
        role: 'user',
        content: title,
        component: userComponent,
        images: this.imagePreviews.map((preview) => preview.url),
      });
      this.userMessage = '';
    } else {
      return;
    }

    if (!message) {
      message = title;
    }

    var userMessage = message;

    // console.log('userMessage:', userMessage);
    // console.log('userMessage:', userMessage);
    // console.log('assitantid:', this.assitantid);

    // console.log('threadid:', this.threadid);

    const mapping = this.componentMapping.find(
      (map) => map.keyword === userMessage
    );

    if (mapping) {

      try {
        // 嘗試解析 JSON 字串
        var jsonobject = JSON.parse(mapping.jsondata);
        // 如果解析成功，調用方法並傳遞 jsonobject
        this.addNewConponentMessage(mapping.component, jsonobject);
      } catch (error) {
        // 如果解析失敗，調用方法並只傳遞 component
        this.addNewConponentMessage(mapping.component);
      }

      this.finishLoading();

    } else {

    //如果有上傳圖片，需要先轉到使用一般的model，不能使用助手
    //直接這樣使用，threadid會跑掉，會導致出現錯誤
       //assistant模型，目前還不支援上傳圖片，先把 imageAttachments 拿掉
       this.openAiApiService
       .chatwithassistant(userMessage, this.assistantid, this.threadid)
       .subscribe((response) => {
        //  console.log('response:', response);

         this.assistantReply = response.reply;
         this.threadid = response.threadid;
        //  console.log('assistant reply:', this.assistantReply);

         if (this.assistantReply.includes('"component"')) {
           //找到整段json的內容
          //  console.log('找到component');
           this.HandleConponentString(this.assistantReply);
         } else {
           var result = this.contentfransfer(this.assistantReply);

          //  console.log('result:', result);

          //拆解出文字跟button的部分

           this.chatMessages.push({
             role: 'assistant',
             content: result ,
             component: '',
           });



           // 通知 Angular 檢測變更並更新 DOM
           this.cdr.detectChanges();

           // 延遲執行查詢和事件綁定，確保 DOM 已經完全更新，否則會找不到元素
           setTimeout(() => {
             this.setchatlink();
           }, 0);

           this.finishLoading();
         }
       });



    }
  }





  HandleConponentString(componentString: string) {
    const jsonMatch = componentString.match(/\{[\s\S]*?\}/);

    if (jsonMatch) {
      try {
        const jsonObject = JSON.parse(jsonMatch[0]);
        // console.log('提取到的 JSON 对象:', jsonObject);

        var componentname = jsonObject.component;
        // console.log('componentname:', componentname);

        //將其他的屬性放到陣列中
        this.addNewConponentMessage(componentname, jsonObject);
      } catch (error) {
        console.error('解析 JSON 出错:', error);
      } finally {
        this.finishLoading();
      }
    } else {
      // console.log('未找到 JSON 内容');
      this.finishLoading();
    }
  }

  chatwithmodel(title: string, message?: string, component?: string,threadid?:string) {
    // console.log('chatwithmodel');

    const AIModel = 'gpt-4o';
    const userComponent = component || '';

    if (title !== '') {
      this.chatMessages.push({
        role: 'user',
        content: title,
        component: userComponent,
        images: this.imagePreviews.map((preview) => preview.url),
      });
      this.userMessage = '';
    } else {
    this.finishLoading();
      return;
    }

    if (!message) {
      message = title;
    }

    const userMessage = message;

    const imageAttachments: string[] = this.imagePreviews.map(
      (preview) => preview.url
    );

    this.openAiApiService
      .chatwithgpts(userMessage, imageAttachments, this.threadid, AIModel)
      .subscribe((response) => {
        this.assistantReply = response.reply;

        if(threadid){
          this.threadid = threadid; //用來接從assistant回傳的threadid
        }else{
          this.threadid = response.threadid; //保持跟model的threadid一致
        }

        // this.threadid = response.threadid;

        this.chatMessages.push({
          role: 'assistant',
          content: this.assistantReply,
          component: '',
          images: this.imagePreviews.map((preview) => preview.url),
        });
        this.userMessage = '';
        this.imagePreviews = [];
        this.selectedFiles = [];

        this.finishLoading();

      });
  }

  getSanitizedContent(content: string): SafeHtml {
    content = content.replace(/\n/g, '<br>');
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

  //針對最後一個產生的assistant對話，新增裡面的Click事件
  setchatlink() {
    // const chatContainer = document.querySelector('.chat-container'); // 找到 .chat-container 元素
    // if (chatContainer) {
    //   const assistantDivs = chatContainer.querySelectorAll('.assistant'); // 查詢 chatContainer 裡面的 .assistant 元素
    //   console.log('assistantDivs:', assistantDivs);

    //   // 如果存在 .assistant 元素，則取最後一個元素
    //   const lastAssistantDiv = assistantDivs[assistantDivs.length - 1];

    //   if (lastAssistantDiv) {
    //     // 查詢最後一個 .assistant 裡面的所有 .canclick 元素
    //     const canclickElements = lastAssistantDiv.querySelectorAll('.canclick');
    //     console.log('canclickElements:', canclickElements);

    //     // 可以在這裡綁定點擊事件
    //     canclickElements.forEach((canclick) => {
    //       canclick.classList.add('canclick');

    //       this.renderer.listen(canclick, 'click', () => {
    //         const value = canclick.getAttribute('data-value') || '';
    //         this.setInputValue(value);
    //       });
    //     });
    //   }
    // }
  }

  loadComponents(componentKey: string, jsondata: string = ''): void {
    // console.log('componentKey:', componentKey);
    // console.log("jsondata",jsondata);

    //資料變更後重新渲染元件
    const containers = this.dynamicComponentContainers.toArray();
    const lastContainerIndex = containers.length - 1;
    //找到最後一個容器
    const container = containers[lastContainerIndex];

    const componentClass = COMPONENT_MAP[componentKey];

    if (!container) {
      console.error(`找不到對應的容器: index ${lastContainerIndex}`);
      return;
    }

    const componentRef: ComponentRef<any> =
      container.createComponent(componentClass);

    //將回傳的參數，送進去component
    if (jsondata != '') {
      Object.keys(jsondata).forEach((key) => {
        const value = jsondata[key as keyof typeof jsondata];
        // console.log(`Key: ${key}, Value: ${value}`);

        if (key != 'component') {
          componentRef.instance[key] = value;
        }
      });
    }


    //針對特別的component做處理
    if (componentKey === 'simulateExamComponent') {

      //寫入課程名稱
       componentRef.instance["classname"] = this.modelname;

      // // 動態監聽 @Output 事件
      // componentRef.instance.explanationGenerated.subscribe(
      //   (content: string) => {
      //     this.chatwithAI(content);
      //   }
      // );

      // // 設定子元件的事件傳遞
      // componentRef.instance.valueSubmitted.subscribe((data: any) => {
      //   this.onValueSubmitted(data, lastContainerIndex);
      // });

    }

    if (componentKey === 'filedownloader') {
      componentRef.instance.containerName = this.containerName;
    }

    this.componentRefs.push(componentRef); // 儲存子元件的引用
  }

  ngOnDestroy(): void {
    // 清除組件參考，避免記憶體洩漏
    for (const componentRef of this.componentRefs) {
      if (componentRef) {
        componentRef.destroy();
      }
    }
  }

  // chatwithassistantSSE() {
  //   const userMessage = this.userMessage;
  //   this.chatMessages.push({ role: 'user', content: userMessage });

  //   // 使用 SSE 來接收回應
  //   this.openAiApiService
  //     .chatWithAssistantSSE(userMessage, this.assitantid)
  //     .subscribe({
  //       next: (response) => {
  //         // 檢查是否已有助手的回應在進行，如果有，則更新該回應
  //         if (
  //           this.chatMessages.length > 0 &&
  //           this.chatMessages[this.chatMessages.length - 1].role === 'assistant'
  //         ) {
  //           // 更新助手的回應內容，逐步追加流式內容
  //           this.chatMessages[this.chatMessages.length - 1].content += response;
  //         } else {
  //           // 如果助手還沒有回應，新增一條訊息，並開始流式顯示
  //           this.chatMessages.push({ role: 'assistant', content: response });
  //         }
  //         this.cdr.detectChanges(); // 手動觸發變更檢測
  //       },
  //       error: (error) => {
  //         console.error('Error:', error);
  //       },
  //       complete: () => {
  //         console.log('Conversation completed');
  //       },
  //     });

  //   this.userMessage = '';
  // }

  contentfransfer(content: string): string {
    // // 替換以數字開頭的選項行，加上 `<a>` 標籤
    // content = content.replace(/^\(\d+\)\s.*$/gm, (match) => {
    //   return `<a class='canclick' href="#" onclick="return false;" data-value='${match}'>${match}</a>`;
    // });

    // // 匹配並替換被【】包含的所有文字，去除【】符號
    // content = content.replace(/【([^【】]+)】/g, (match, p1) => {
    //   return `<a class='canclick' href="#" onclick="return false;" data-value='${p1}'>${p1}</a>`;
    // });

    return content;
  }

  setInputValue(value: string) {
    this.userMessage = value;
  }

  // 捕捉按鍵事件
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // 阻止默認的換行行為
      this.chatwithAI(this.userMessage);
      this.userMessage = ''; // 清空輸入框
    }
  }

  closeDiv() {
    this.showintroduction = true;
    // this.showchat = true;
    // this.showrecommentlist = false;

    this.chatMessages = [];

    //清空threadid
    this.threadid = '';
  }

  onIconClick(icon: string) {
    this.selectedIcon = icon; // 設定當前選取的圖示

    if (icon == 'sad') {
      this.slogan = '(明日は明日の風が吹く)';
    } else {
      this.slogan = '';
    }
  }

  //導頁到檔案中心
  gotofilecenter() {
    this.router.navigate(['/filebank'], {
      queryParams: { vector_store_id: this.vector_store_id },
    });
  }

  /**
   * 動態加載元件並插入到 chatMessages
   */
  addNewConponentMessage(componentKey: string, jsondata: string = ''): void {

    const componentClass = COMPONENT_MAP[componentKey];

    // console.log('componentClass:', componentClass);

    if (!componentClass) {
      console.error(`找不到對應的元件: ${componentKey}`);
      return;
    }

    //將元件加入到 chatMessages
    this.chatMessages.push({
      role: 'assistant',
      content: '',
      component: componentKey,
    });

    // console.log('chatMessages:', this.chatMessages);

    // 通知 Angular 檢測變更並更新 DOM
    this.cdr.detectChanges();

    // 延遲執行查詢和事件綁定，確保 DOM 已經完全更新，否則會找不到元素
    // render元件
    setTimeout(() => {
      this.loadComponents(componentKey, jsondata);
    }, 0);
  }

  onValueSubmitted(data: any, index: number) {
    // console.log('來自子元件的資料:', data);
    // console.log('該元件在 chatMessages 的索引:', index);
    // 可以將資料寫入資料庫或進一步處理
  }

  // 自動調整輸入框高度
  autoResize(event: Event) {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = 'auto'; // 先重置高度，避免疊加
    textarea.style.height = textarea.scrollHeight + 'px';
  }

  // 當用戶選擇文件時
  async onFilesSelected(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      for (let i = 0; i < inputElement.files.length; i++) {
        const file = inputElement.files[i];

        // 生成本地預覽 URL
        const localPreviewUrl = URL.createObjectURL(file);
        this.imagePreviews.push({
          url: localPreviewUrl,
          uploading: true,
          progress: 0,
        });
        this.selectedFiles.push(file);

        // 模擬上傳進度
        const index = this.imagePreviews.length - 1;

        try {
          // 上傳檔案到 Azure Blob Storage 並取得 URL
          const fileUrl = await this.azureBlobService.uploadFile(file);
          // console.log('Uploaded file URL:', fileUrl);

          // 上傳成功後更新預覽的 URL 並標記為上傳完成
          //先不處理uploading的動畫，改成Loading完成後，可以點擊
          this.imagePreviews[index].url = fileUrl;
          this.imagePreviews[index].uploading = false;
        } catch (error) {
          console.error('Error uploading file:', error);
          this.imagePreviews[index].uploading = false;
          alert('檔案上傳失敗，請重試。');
        }
      }
    }
  }

  // 觸發檔案上傳
  triggerFileUpload() {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  }

  // 移除指定索引的圖片預覽
  removeImage(index: number) {
    this.imagePreviews.splice(index, 1);
    this.selectedFiles.splice(index, 1);
  }

  triggerQRCodeUpload(): void {
    const qrCodeInput = document.getElementById(
      'qrCodeInput'
    ) as HTMLInputElement;
    qrCodeInput.click(); // 打開相機或圖片選擇器
  }

  onQRCodeUpload(event: Event): void {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          if (context) {
            // 壓縮圖片，限制寬度和高度
            const maxDimension = 500; // 最大邊長，根據需求可調整
            const scale = Math.min(maxDimension / img.width, maxDimension / img.height);
            canvas.width = img.width * scale;
            canvas.height = img.height * scale;

            context.drawImage(img, 0, 0, canvas.width, canvas.height);

            // 獲取圖片像素數據並解析 QR Code
            const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
            const qrCode = jsQR(imageData.data, canvas.width, canvas.height);

            if (qrCode) {
              const text = qrCode.data;

              if (text.startsWith('QRData')) {
                const result = text.replace(/^QRData[:：]/, ''); // 清除前綴
                this.chatwithAI(result);
              } else {
                alert('內容或格式不符，無法解析');
              }
            } else {
              alert('未檢測到有效的 QR Code');
            }
          }
        };
        if (reader.result) {
          img.src = reader.result.toString();
        }
      };
      reader.readAsDataURL(file);
    }
  };

  currentFontSize: number = 16; // 預設字體大小

  adjustFontSize(action: 'increase' | 'decrease') {
    const chatDiv = document.getElementById('chatdiv');
    if (chatDiv) {
      if (action === 'increase' && this.currentFontSize < 32) {
        this.currentFontSize += 2;
      } else if (action === 'decrease' && this.currentFontSize > 16) {
        this.currentFontSize -= 2;
      }
      chatDiv.style.fontSize = `${this.currentFontSize}px`;
    }
  }

  clearmsg(){
    this.chatMessages = [];
  }

  gotolobby(){
    this.router.navigate(['/lobby']);
  }

  toggleVisibility() {
    this.showintroduction = !this.showintroduction;
    // 這裡填入隱藏/顯示的邏輯
  }

  finishLoading(){
    this.isSendingMessage = false;
    this.scrollToBottom();
  }

  // 滾動到對話視窗底部
  scrollToBottom(): void {
    const chatContainer = document.getElementById('chat-container');
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }

}
