import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { environment } from '../../environments/environment';
import { LoginService } from '../../services/login/login.service';

@Component({
  selector: 'app-login',
  standalone: true,
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  imports: [CommonModule, ReactiveFormsModule, RouterModule], // 導入必要的模組
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;

  login_title = '';
  logoUrl = '';
  copyright = '';
  version = '';
  clientId = '';
  companyname = "";

  isSubmitting = false;

  //Login頁面的文字顯示
  title_text = environment.login_page_setting.title_text || "Login";
  id_text = environment.login_page_setting.id_text || "ID";
  pw_text = environment.login_page_setting.pw_text || "Password";
  login_text = environment.login_page_setting.login_text || "Login";
  forget_text = environment.login_page_setting.forget_text || "Forgot Password?";

  constructor(
    private fb: FormBuilder,
    private router: Router, // 注入 Router
    private loginService: LoginService // 使用自定義的 LoginService
  ) {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  ngOnInit() {
    // 載入設定檔
    this.logoUrl = environment.Login_logoUrl;
    this.copyright = environment.copyright;
    this.login_title = environment.Login_title;
    this.version = environment.version;
    this.clientId = environment.clientId;
    this.companyname = environment.companyname;
  }

  async onSubmit() {
    if (this.loginForm.valid) {
      this.isSubmitting = true; // 按鈕進入 disabled 狀態
      const loginData = this.loginForm.value;

      try {
        const result = await this.loginService.login(this.clientId, loginData);

        // console.log('Login result:', result);

        if (result.success) {
          // console.log('Login successful:', result);

          // 儲存使用者資訊
          localStorage.setItem('authToken', 'dummy-token');
          localStorage.setItem('username', result.username);
          localStorage.setItem('modules', JSON.stringify(result.modules));

          // 顯示歡迎訊息
          // if(this.companyname == "Tpic"){
          // alert(`Welcome, ${result.username}! You have access to modules: ${result.modules.join(', ')}`);
          //  };

          // 導向主頁
          this.router.navigate(['/lobby']);
        } else {
          // 登入失敗處理
          alert('Invalid username or password');
        }
      } catch (error) {
        console.error('Login failed:', error);
        alert('An error occurred during login.');
      }
      finally {
        this.isSubmitting = false; // 無論成功或失敗，恢復按鈕可用
      }

    }
  }

}
