import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';  // 引入錯誤處理操作符
import { HttpClient } from '@angular/common/http';
import axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

 private apiUrl = environment.apiUrl;  // 從環境變數讀取後端 API URL

 constructor(private http: HttpClient) {}


// 將資料發送到後端 API
async sendDataToBackend(data: any): Promise<any> {
  try {

    console.log('Data to send:', `${this.apiUrl}/api/cosmosdb/saveData`);

    // 設定後端 API 路徑
    const response = await axios.post(`${this.apiUrl}/api/cosmosdb/saveData`, { data });
    // 成功後返回從後端接收到的資料
    console.log('Data successfully sent to backend:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error sending data to backend:', error);
    throw error;
  }
}

//   // 呼叫 getDatabyquery API，並傳遞查詢參數
//  // 使用 axios 呼叫 getDatabyquery API，並傳遞查詢參數
getDataByQuery(filter: any, pageSize: number = 10, continuationToken?: string): Observable<any> {

  console.log('getDataByQuery');

  // 構造查詢字串
  let params = new URLSearchParams();
  params.set('filter', JSON.stringify(filter));  // 將過濾條件轉換為 JSON 字串並傳遞
  params.set('pageSize', pageSize.toString());

  if (continuationToken) {
    params.set('continuationToken', continuationToken);
  }

  // 正確的 API 路徑
  const url = `${this.apiUrl}/api/cosmosdb/getDatabyquery`;  // 更新 URL 路徑

  // 使用 axios 發送 GET 請求
  return new Observable(observer => {
    axios.get(url, { params })  // 使用正確的 URL
      .then(response => {
        observer.next(response.data);
        observer.complete();
      })
      .catch(error => {
        observer.error(error);
      });
  });
}

getexamquestions(courseName: string, numQuersion: number = 80): Observable<any> {

  // 構造查詢字串
  let params = new URLSearchParams();
  params.set('courseName', courseName);
  params.set('numQuestions', numQuersion.toString());  // 將 numQuersion 轉換為字串

  // 正確的 API 路徑
  const url = `${this.apiUrl}/api/examdb/getQuestions`;  // 更新 URL 路徑

  // 使用 axios 發送 GET 請求
  return new Observable(observer => {
    axios.get(url, { params })  // 使用正確的 URL
      .then(response => {
        observer.next(response.data);
        observer.complete();
      })
      .catch(error => {
        observer.error(error);
      });
  });
}



}
