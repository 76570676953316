import { Component } from '@angular/core';
import { OpanaiService } from '../../../services/openai.service';
import { AzureBlobService } from '../../../services/azure-blob.service'; // 假設 Azure Blob Service 已經存在
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { getDocument, PDFDocumentProxy, GlobalWorkerOptions } from 'pdfjs-dist';
import { ChangeDetectorRef } from '@angular/core';
import { tsv } from 'd3';

interface TextContent {
  page: number;
  data: string;
  AIRespnese?: string;
  status?: string;
}
@Component({
  selector: 'app-pdf-helper',
  templateUrl: './pdf-helper.component.html',
  styleUrls: ['./pdf-helper.component.scss'],
  standalone: true,
  imports: [FormsModule, CommonModule],
})
export class PdfHelperComponent {
  pdfFile: File | null = null;
  textContent: TextContent[] = [];

  imageContent: { page: number; data: string }[] = [];
  prompt: string = '';
  jsonformat: string = '';
  totalpage: number = 0;
  totalword: number = 0; // 總字數

  jsonresult: string[] = [];
  AIResult: string = '';

  isLoading = false; // 處理狀態

  constructor(
    private openAiApiService: OpanaiService,
    private azureBlobService: AzureBlobService,
    private cdRef: ChangeDetectorRef // 注入 ChangeDetectorRef
  ) {
    // 設定 PDF.js 的全局 WorkerSrc，指向正確的本地版本
    GlobalWorkerOptions.workerSrc = '/assets/js/pdf.worker.min.mjs';
  }

  async onFileSelected(event: Event): Promise<void> {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.pdfFile = input.files[0];
    }
  }


  deleteextrawork(text: string): string {

    //拿掉第 1 頁這類的多於文字
    text = text.replace(/第\s*\d+\s*頁/g, '');

    return text
  }

  async convertPDF(toText: boolean): Promise<void> {
    if (!this.pdfFile) {
      alert('請先上傳PDF檔案');
      return;
    }

    const fileReader = new FileReader();
    fileReader.onload = async (e) => {
      const arrayBuffer = e.target?.result as ArrayBuffer;

      // 使用 PDF.js 進行解析
      const pdf: PDFDocumentProxy = await getDocument({ data: arrayBuffer }).promise;

      this.textContent = []; // 清空文字內容
      this.imageContent = []; // 清空圖片內容

      this.totalpage = pdf.numPages;

      // 取得當前時間並格式化
      const now = new Date();
      const timestamp = `${now.getFullYear()}${String(
        now.getMonth() + 1
      ).padStart(2, '0')}${String(now.getDate()).padStart(2, '0')}${String(
        now.getHours()
      ).padStart(2, '0')}${String(now.getMinutes()).padStart(2, '0')}${String(
        now.getSeconds()
      ).padStart(2, '0')}`;

      // 提取 PDF 檔案名稱（去除副檔名）
      const pdfName = this.pdfFile?.name.replace(/\.[^/.]+$/, '') || 'unknown';
      // pdf.numPages
      for (let i =1; i <=pdf.numPages ; i++) {
        const page = await pdf.getPage(i);

        if (toText) {
          // 提取文字內容
          const parserresult = await page.getTextContent();

          this.totalword += parserresult.items.length;

          let pageText = '';

          parserresult.items.forEach((item: any) => {

            //針對正解的部分，進行換行
            if(['(', '（'].some(char => item.str.includes(char)) && item.transform[4] < 30){
              item.str = item.str.replace('(','【');
              item.str = item.str.replace('（','【');
              pageText += '\n\n';
            }

             //針對正解的部分，進行換行
             if([')', '）'].some(char => item.str.includes(char))&& item.transform[4] < 50 ){
              item.str = item.str.replace('）','】');
              item.str = item.str.replace(')','】');
            }

            pageText += item.str;
          });

          //清除不必要的文字
         const beautText = this.deleteextrawork(pageText);

          this.textContent.push({ page: i, data: beautText });
        } else {
          // 提取圖片內容（生成數據URI）
          const viewport = page.getViewport({ scale: 2 });
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.width = viewport.width;
          canvas.height = viewport.height;

          if (context) {
            await page.render({ canvasContext: context, viewport }).promise;

            // 將圖片轉為 Blob
            const blob = await new Promise<Blob | null>((resolve) =>
              canvas.toBlob(resolve, 'image/png')
            );
            if (blob) {
              // 檔案命名規則: yyyymmddhhmmss-{pdfname}-page-${i}.png
              const fileName = `${timestamp}-${pdfName}-page-${i}.png`;

              // 使用 Azure Blob Service 上傳文件
              const fileUrl = await this.azureBlobService.uploadFileByBlob(
                blob,
                fileName
              );

              console.log(fileUrl);

              // 將返回的 URL 存入 imageContent
              this.imageContent.push({ page: i, data: fileUrl });
            }
          }
        }
      }

      //頁面全部處理完畢，針對文字跑到下一頁的進行處理

      var length = this.textContent.length;

      //這邊從1開始，方便跳過第一頁
      for (let i = 1; i < length; i++) {
        var data = this.textContent[i].data;
        //取得第一行
        var firstline = this.getFirstLine(data);

        //如果第一行不是以(開頭，或者字數小於20，則將第一行加到上一頁的最後
        if(firstline.startsWith("(")== false || firstline.length < 20){
          this.textContent[i-1].data = this.textContent[i-1].data + firstline;
          this.textContent[i].data = this.textContent[i].data.replace(firstline, '').trim();
        }

      }

    };

    fileReader.readAsArrayBuffer(this.pdfFile);
  }


 getFirstLine(text: string): string {
    // 使用正則表達式匹配第一行，直到遇到換行符或者其他分隔符
    const match = text.match(/^([^\n]+)/);
    return match ? match[0] : '';  // 返回匹配到的第一行，如果沒有匹配則返回空字符串
  }

  getConvertedText(): string {
    return this.textContent
      .map((content) => `Page ${content.page}:<br>${content.data}`)
      .join('<br><br>');
  }

  async sendToAI(pageindex:number =-1): Promise<void> {

    this.isLoading = true; // 開始處理請求，設置 loading 狀態為 true

    this.prompt =
      '請把以下的考古題內容轉成以下的json格式。每一個題目之間會換行，題目的的結構是 【正確答案】 題號(會是遞增的數字) 題目與選項(通常是(1)(2)(3)(4))。正確答案(correctAnswerIndex)要跟文件裡的正確答案數字一模一樣，不要自己修改。只需要傳json給我，不用做任何額外的說明。\n\n'
      + "將正確答案寫入correctAnswerIndex，將題號寫入id，將題目與選項寫入text與options。";

    this.jsonformat = JSON.stringify([
      {
        correctAnswerIndex: [4],
        id:1,
        text: '在道路施工時，為防止工作人員遭車輛撞擊之交通事故，對於出入口之防護措施，下列何者有誤？',
        options: [
          '管制非工作人員不得進入',
          '設置警告標示',
          '工地大門置交通引導人員',
          '各包商之車輛一律停放於工地現場',
        ]
      },
      {
        correctAnswerIndex: [2],
        id:2,
        text: '安全帶或安全母索繫固之錨錠，至少應能承受每人下列何者之拉力？',
        options: ['1000公斤', '1300公斤', '2000公斤', '2300公斤']
      },
    ]);


    const totalPages = this.textContent.length; // 總頁數
    let completedRequests = 0; // 記錄完成的請求數量

    // 根據 pageindex 判斷是否要處理所有頁面或特定頁面
    const pagesToProcess = pageindex === -1 ? this.textContent : [this.textContent[pageindex-1]];

    if(pageindex != -1){
      //reset status
      this.textContent[pageindex-1].status = '';
      this.textContent[pageindex-1].AIRespnese = '';
    }
    else{
      //避免單次重抓的時候被清空
      this.jsonresult = []; // 清空結果
    }

    for (const item of pagesToProcess) {

      try {
        var finalmessage =
          this.prompt +
          'json格式如下:' +
          this.jsonformat +
          '內容如下：' +
          item.data;

        // console.log('finalmessage', finalmessage);

        // 呼叫API取得回應
        await this.openAiApiService.chatwithgpts(finalmessage,[],"","gpt-4o").subscribe({
          next: (response) => {
            console.log('API回應:',item.page, response);

            // 去除 markdown 標記，假設回應是以 "```json" 開頭並以 "```" 結尾
            const cleanedResponse = response.reply
              .replace(/^```json\s*/g, '')
              .replace(/```$/g, '');

            // 將回應存入對應頁面的 AIRespnese
            const page = this.textContent.find((p) => p.page === item.page);
            if (page) {
              page.AIRespnese = cleanedResponse;
              page.status = 'Success';
            }

            const parsedResponse = JSON.parse(cleanedResponse);

            // 將解析後的回應加入 jsonresult
            this.jsonresult.push(...parsedResponse); // 將每一頁的回應加入 jsonresult

            this.getjsonresult();

            // 檢查是否所有請求都完成
            completedRequests++;
            if (completedRequests === totalPages) {
              this.isLoading = false; // 所有請求完成後設置 loading 狀態為 false
            }

            // 手動觸發 Angular 檢測變更
            this.cdRef.detectChanges();


          },
          error: (err) => {

            const page = this.textContent.find((p) => p.page === item.page);
            if (page) {
              page.status = 'Fail';
            }

            // this.jsonresult.push(`Page ${item.page}: Error`);
            console.log("執行失敗：page" + item.page);

            // 檢查是否所有請求都完成
            completedRequests++;
            if (completedRequests === totalPages) {
              this.isLoading = false; // 所有請求完成後設置 loading 狀態為 false
            }
          },
        });
      } catch (error) {
        console.error('處理失敗:', error);
        // this.jsonresult.push(`Page ${item.page}: Error`);

        const page = this.textContent.find((p) => p.page === item.page);
            if (page) {
              page.status = 'Fail';
            }

        completedRequests++;
        if (completedRequests === totalPages) {
          this.isLoading = false; // 所有請求完成後設置 loading 狀態為 false
        }
      }
    }

    console.log('完整回應:', this.jsonresult);
  }

  getjsonresult(): void {

    //依照id遞增排序
    this.jsonresult.sort((a: any, b: any) => a.id - b.id);

    // 將組合後的 jsonresult 陣列轉換為 JSON 字符串
    this.AIResult = JSON.stringify(this.jsonresult, null, 2);


  }

  downloadJson(): void {

    // 創建一個 Blob 對象，類型設置為 application/javascript
    const blob = new Blob([this.AIResult], { type: 'application/json' });

    // 創建一個臨時的 URL 指向這個 Blob
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);

    // 設置下載檔案的名字為 "考古題.js"
    link.download = '考古題.json';

    // 模擬點擊該鏈接以觸發下載
    link.click();

    // 釋放 URL 資源
    URL.revokeObjectURL(link.href);


  }


  reloaddata(pageidex:number): void {

    this.sendToAI(pageidex);

  }




}
