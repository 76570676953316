
import { AuthenticationStrategy } from './authentication-strategy.interface';
import { TpicAuthenticationStrategy } from './tpic-authentication.strategy';
import { MockAuthenticationStrategy } from './mock-authentication.strategy';
import { QuallicoAuthenticationStrategy } from './quallico-authentication.strategy';

export class AuthenticationStrategyFactory {
  static getStrategy(clientId: string): AuthenticationStrategy {

     console.log("clientId", clientId);

    switch (clientId) {
      case 'Tpic':
        return new TpicAuthenticationStrategy();
        case 'Quallico':
          return new QuallicoAuthenticationStrategy();
      // case 'clientB':
      //   return new MockAuthenticationStrategy();
      default:
        return new MockAuthenticationStrategy();
    }

  }
}
