<div *ngIf="questions.length > 0; else loading" class="component-container">
  <div class="header">
    <h1>電腦測驗 - 線上模擬系統</h1>
    <h3>【非正式考試，僅供練習使用】</h3>
    總題數：{{testnumbers}}
    <div class="exam-info">
      <div class="exam-info-left">
        <div>學科：{{ classname }}</div>
        <div>考生姓名：TPIC</div>
        <div>考生編號：113N99900400000</div>
      </div>
      <div class="exam-info-right">
        <div class="clock">倒數時間：{{ remainingTime }}</div>
        <div>
          已答題數：
          <span [ngStyle]="{'color': answeredCount > 0 ? 'blue' : 'black'}">
            {{ answeredCount }}
          </span>
          題 (共 {{ questions.length }} 題)
        </div>

      </div>
    </div>
  </div>
  <div>
    <div class="question-navigation">
      <button (click)="prevQuestion()" [disabled]="currentQuestionIndex === 0">上一題</button>
      <button (click)="nextQuestion()" [disabled]="currentQuestionIndex === questions.length - 1">下一題</button>
      <div class="jump-to">
        <label for="jump">跳到題目：</label>
        <select id="jump" [(ngModel)]="jumpToIndex" (change)="jumpToQuestion()">
          <option *ngFor="let question of questions; let i = index" [value]="i">
            第 {{ i + 1 }} 題
          </option>
        </select>
      </div>
    </div>
    <div class="question">
      <h2>第 {{ currentQuestionIndex + 1}} 題</h2>
      <p>{{ questions[currentQuestionIndex].text }}</p>
      <ul>
        <li *ngFor="let option of questions[currentQuestionIndex].options; let i = index"
        [ngClass]="{
          'correct-answer': showResult && questions[currentQuestionIndex].correctAnswerIndex.includes(i+1),
          'wrong-answer': showResult && questions[currentQuestionIndex].selected === option && !questions[currentQuestionIndex].correctAnswerIndex.includes(i+1)
        }"
        >
          <input
            type="radio"
            id="option-{{ currentQuestionIndex }}-{{ i }}"
            name="option-{{ currentQuestionIndex }}"
            [value]="option"
            (change)="selectAnswer(option)"
            [checked]="questions[currentQuestionIndex].selected === option"
          />
          <label for="option-{{ currentQuestionIndex }}-{{ i }}">
            {{ option }}
          </label>
        </li>
      </ul>
    </div>

    <div class="explanation" >
      <h3 *ngIf="showResult" ><a (click)="goToExplan(currentQuestionIndex)">進階說明</a></h3>
      <div [innerHTML]="questions[currentQuestionIndex].explanation"></div>
    </div>

    <div class="button-container">
    <button (click)="nextQuestion()" [disabled]="currentQuestionIndex === questions.length - 1">下一題</button>
    <button (click)="submitAnswers()">提交答案</button>
    </div>
     <!-- <div class="submit-section"> </div> -->

  </div>

  <div class="result" *ngIf="showResult">
    <h3>答題結果 </h3>

    <div>
      {{answerresult}}
  </div>

    <a *ngFor="let question of questions; let i = index" (click)="goToQuestion(i)" [ngStyle]="{ color: question.isCorrect === null ? 'black' : (question.isCorrect ? 'green' : 'red') }">
      {{ i + 1 }}
    </a>

    <div>
      <span style="color: red;">紅色:錯誤</span>、<span style="color: green;">綠色:正確</span>、<span style="color:black;">黑色:未答</span>
    </div>

  </div>

</div>

<ng-template #loading>
  <p>題目生成中~</p>
</ng-template>
