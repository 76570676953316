import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CommonModule } from '@angular/common';  // 引入 CommonModule

import { BlobServiceClient } from "@azure/storage-blob";
import { saveAs } from 'file-saver';

interface FileInfo {
  name: string;
  status: string;
  size: string;
  createdOn: string;
  contentType: string;
}

@Component({
  selector: 'app-filedownloader',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './filedownloader.component.html',
  styleUrl: './filedownloader.component.scss'
})
export class FiledownloaderComponent implements OnInit {

  private blobServiceClient: BlobServiceClient;
  private downloadedBlob: Blob | null = null; // 儲存下載的 Blob
  private contentType: string = '';           // 儲存檔案的 MIME 類型

  filesize: string = '0 MB';                  // 以 MB 為單位的檔案大小
  createdOn: string = '';                     // 格式化的建立時間
  fileStatus:string="";

  @Input() containerName!: string;
  @Input() fileName!: string;
  fileInfos: FileInfo[] = []; // 儲存每個檔案的資訊

  constructor() {
    const blobUrl = environment.AzureBlob.blobUrl;
    const sasToken = environment.AzureBlob.blobsasToken;
    this.blobServiceClient = new BlobServiceClient(`${blobUrl}?${sasToken}`);
  }

  ngOnInit() {

    // 解析多個檔案名稱，分隔符號為 ';'
    const fileNames = this.fileName.split(';').map((name) => name.trim());
    fileNames.forEach((name) => this.loadFileInfo(name));
  }

  async loadFileInfo(fileName: string) {
    const fileInfo: FileInfo = {
      name: fileName,
      status: 'Loading...',
      size: '0 MB',
      createdOn: '',
      contentType: 'application/octet-stream',
    };

    try {
      const containerClient = this.blobServiceClient.getContainerClient(this.containerName);
      const blobClient = containerClient.getBlobClient(fileName);

      // 取得 Blob 的屬性資訊
      const properties = await blobClient.getProperties();

      // 設置檔案資訊
      fileInfo.size = this.formatFileSize(properties.contentLength ?? 0);
      fileInfo.createdOn = this.formatDate(properties.createdOn);
      fileInfo.contentType = properties.contentType ?? 'application/octet-stream';
      fileInfo.status = 'Y';
    } catch (error) {
      fileInfo.status = 'N';
      console.error(`Error loading file properties for ${fileName}:`, error);
    }

    // 儲存檔案資訊到陣列中
    this.fileInfos.push(fileInfo);
  }


  private async fetchBlob(fileName: string): Promise<Blob | null> {
    try {
      const containerClient = this.blobServiceClient.getContainerClient(this.containerName);
      const blobClient = containerClient.getBlobClient(fileName);

      const downloadBlockBlobResponse = await blobClient.download();
      const blob = await downloadBlockBlobResponse.blobBody ?? null;

      if (blob) {
        console.log('Blob downloaded successfully');
        return blob;
      } else {
        console.error('Download failed: blobBody is undefined.');
        return null;
      }
    } catch (error) {
      console.error(`Error fetching blob for ${fileName}:`, error);
      return null;
    }
  }


  async downloadBlobFile(fileName: string) {
    const blob = await this.fetchBlob(fileName);
    if (blob) {
      saveAs(blob, fileName);
    }
  }

  async preview(fileName: string) {
    const fileInfo = this.fileInfos.find((file) => file.name === fileName);
    if (!fileInfo) {
      console.error(`File info not found for ${fileName}`);
      return;
    }

    const blob = await this.fetchBlob(fileName);

    if (blob) {
      const dynamicBlob = new Blob([blob], { type: fileInfo.contentType });
      const blobUrl = URL.createObjectURL(dynamicBlob);
      window.open(blobUrl, '_blank');
      URL.revokeObjectURL(blobUrl);
    }
  }

  // 格式化檔案大小為 MB
  private formatFileSize(sizeInBytes: number): string {
    const sizeInMB = sizeInBytes / (1024 * 1024);
    return `${sizeInMB.toFixed(2)} MB`;
  }

  // 格式化日期為 yyyy-mm-dd hh:mm:ss
  private formatDate(date: Date | undefined): string {
    if (!date) return '';
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const dd = String(date.getDate()).padStart(2, '0');
    const hh = String(date.getHours()).padStart(2, '0');
    const min = String(date.getMinutes()).padStart(2, '0');
    const ss = String(date.getSeconds()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd} ${hh}:${min}:${ss}`;
  }
}
