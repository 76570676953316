import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { OpanaiService } from '../../../services/openai.service';
import { DatabaseService } from '../../../services/database.service';

@Component({
  selector: 'app-simulate-exam',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './simulate-exam.component.html',
  styleUrls: ['./simulate-exam.component.scss']
})
export class SimulateExamComponent {

  @Input() classname :string = ""; //科目名稱

  @Input() questions: Array<{
    text: string;
    options: string[];
    correctAnswerIndex: number[];
    selected: string | null;
    isCorrect?: boolean| null; // 新增屬性來標記答題正確性
    explanation?: string; // 新增屬性來存放詳解
  }> = []; // 從母元件傳入的題目資料

  @Input() totalminutes: number =100; // 考試時間 - 預設是20分鐘
  @Input() testnumbers: number =80; // 題目數目 - 預設是10分鐘

  @Output() valueSubmitted = new EventEmitter<any>(); // 用於回傳數據
  @Output() explanationGenerated = new EventEmitter<string>(); // 定義輸出事件

  remainingTime: string = ''; // 倒數計時器預設顯示的文字
  timer: any;
  currentQuestionIndex: number = 0; // 當前題目索引
  jumpToIndex: number = 0; // 跳題的下拉選單值
  answeredCount: number = 0; // 已回答題目數
  showResult: boolean = false; // 是否顯示答題結果

  answerresult: string = ""; // 答題結果

  assistantid = "asst_Eoq4YShI4IJex9m6Ni8XUkM6"; //AI助手ID

  constructor(
    private openAiApiService: OpanaiService,
    private databaseService: DatabaseService,
  ) {

  }

  ngOnInit() {
    // 在 ngOnInit 中啟動計時器，才吃到到母元件送進來的值

    this.loadqeustions();
    this.startTimer();
    this.updateAnsweredCount();
  }

  loadqeustions() {

    console.log("classname", this.classname);

    this.databaseService.getexamquestions(this.classname, this.testnumbers).subscribe((response) => {

      // console.log("response", response);

      this.questions = response.map((question: any) => ({
        text: question.text,
        options: question.options,
        correctAnswerIndex: question.correctAnswerIndex,
        selected: null, // 初始化 selected 為 null
        isCorrect: null, // 初始化 isCorrect 為 false
      }));

      //  console.log("this.questions", this.questions);

    });

    // //從CosmosDB取得題目、隨機抓80題
    // if(this.classname == "營造業甲種職業安全衛生業務主管"){

    //   this.databaseService.getexamquestions(this.classname, this.testnumbers).subscribe((response) => {

    //     // console.log("response", response);

    //     this.questions = response.map((question: any) => ({
    //       text: question.text,
    //       options: question.options,
    //       correctAnswerIndex: question.correctAnswerIndex,
    //       selected: null, // 初始化 selected 為 null
    //       isCorrect: null, // 初始化 isCorrect 為 false
    //     }));

    //      console.log("this.questions", this.questions);

    //   });

    // }
    // else{

    // const userMessage = `【課程名稱：${this.classname}｜模式：模擬測驗 | 題數：${this.testnumbers}】`;

    // //用來取得考試題目
    // this.openAiApiService
    //   .chatwithassistant(userMessage, this.assistantid)
    //   .subscribe((response) => {

    //     try {
    //       // 去掉前後的多餘引號
    //       const cleanedResponse = response.reply.trim().replace(/^"|"$/g, '');
    //       // 解析 JSON 並將其賦值給 questions
    //       const parsedQuestions = JSON.parse(cleanedResponse);
    //       if (Array.isArray(parsedQuestions)) {
    //         this.questions = parsedQuestions;
    //       } else {
    //         console.error('返回的題目格式錯誤:', parsedQuestions);
    //       }
    //     } catch (error) {
    //       console.error('解析返回的題目時發生錯誤:', error);
    //     }
    //   });

    // }


  }


  startTimer() {
    let time = this.totalminutes * 60; // 80 分鐘轉為秒
    this.timer = setInterval(() => {
      const minutes = Math.floor(time / 60);
      const seconds = time % 60;
      this.remainingTime = `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
      if (time === 0) {
        clearInterval(this.timer);
        alert('時間到，請提交答案！');
      }
      time--;
    }, 1000);
  }

  prevQuestion() {
    if (this.currentQuestionIndex > 0) {
      this.currentQuestionIndex--;
    }
  }

  nextQuestion() {
    if (this.currentQuestionIndex < this.questions.length - 1) {
      this.currentQuestionIndex++;
    }
  }

  jumpToQuestion() {
    this.currentQuestionIndex = Number(this.jumpToIndex); // 確保是數字類型
  }

  selectAnswer(option: string) {
    this.questions[this.currentQuestionIndex].selected = option;
    this.updateAnsweredCount();
  }

  submitAnswers() {
  clearInterval(this.timer);

  let correctCount = 0;
  let totalansCount=0;

  this.questions.forEach((question) => {
    // 如果沒有選擇答案，保持 isCorrect 為 null
    if (question.selected === null) {
      question.isCorrect = null;
      return;  // 如果未答，則跳過此題
    }

    totalansCount++;

    // 確保 correctAnswerIndex 是一個陣列
    if (!Array.isArray(question.correctAnswerIndex)) {
      question.isCorrect = false; // 如果正確答案索引錯誤，設為錯誤
      return;
    }

    const selectedIndex = question.options.indexOf(question.selected!);
    question.isCorrect = question.correctAnswerIndex.includes(selectedIndex + 1); //因為答案是用1開始，所以要+1

    if (question.isCorrect) {
      correctCount++;
    }
  });

  this.answerresult = `您共回答了 ${totalansCount} 題，答對了 ${correctCount} 題，共取得 ${correctCount * 1.25} 分。`;

    // 顯示結果
    this.showResult = true;

    // // 回傳提交結果給母元件
    // this.valueSubmitted.emit({
    //   correctCount,
    //   total: this.questions.length,
    //   questions: this.questions,
    // });
  }

  updateAnsweredCount() {
    this.answeredCount = this.questions.filter(q => q.selected !== null).length;
  }

  goToQuestion(index: number) {
    this.currentQuestionIndex = index; // 更新當前題目索引
    this.jumpToIndex = index; // 更新跳題選單的值（如果需要同步顯示）
  }

  goToExplan(index: number) {

    // 更新當前題目索引和跳題選單值
    this.currentQuestionIndex = index;
    this.jumpToIndex = index;

    this.questions[index].explanation = "正在取得詳細說明...";

    // 取得題目內容和答案
    const questiontext = this.questions[index].text; // 題目文字
    const correctIndexes = this.questions[index].correctAnswerIndex; // 答案索引
    const options = this.questions[index].options; // 選項

    // 獲取正確答案的選項文字
    const correctAnswers = correctIndexes.map((i) => options[i-1]).join(", ");

    // 替換模板中的佔位符
    const content = `在你提供的題目中，為什麼 "${questiontext}" 的答案是 "${correctAnswers}" 呢？`;

     // 呼叫API取得回應
    this.openAiApiService.chatwithassistant(content,this.assistantid).subscribe({
      next: (response) => {

        this.questions[index].explanation = this.removeReferencesAndAfter(response.reply);

      },
      error: (err) => {

      },
    });


  }

 removeReferencesAndAfter(text: string): string {
    // 使用正則表達式來移除 "References" 及其後的所有內容
    return text.replace(/References.*$/, '').trim();
  }

}
