import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-form-linker',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './form-linker.component.html',
  styleUrls: ['./form-linker.component.scss']
})
export class FormLinkerComponent {
  @Input() categories: string[] = []; // 接收要顯示的類別
  @Input() preselectedFormId: string | null = null; // 接收預設選中的表單 ID

  mode: 'existing' | 'new' | null = null; // 切換模式
  allCategories = ['Actions', 'Improvement']; // 原始所有類別
  formCategories: string[] = []; // 當前顯示的類別
  existingForms = [
    { id: 'A1', name: 'Form A1', category: 'Actions' },
    { id: 'A2', name: 'Form A2', category: 'Actions' },
    { id: 'A3', name: 'Form A3', category: 'Actions' },
    { id: 'I1', name: 'Form I1', category: 'Improvement' },
    { id: 'I2', name: 'Form I2', category: 'Improvement' },
    { id: 'I3', name: 'Form I3', category: 'Improvement' },
  ];
  filteredForms = this.existingForms; // 篩選後的表單列表
  selectedFormId: string | null = null;
  newFormName: string = '';
  selectedCategory: string = ''; // 定義選中的類別
  isFormCreationVisible: boolean = false; // 控制表單建立過程的可見性

  ngOnInit() {
    // 初始化時過濾類別
    this.filterForms();
    if (this.preselectedFormId) {
      this.selectedFormId = this.preselectedFormId;
    }
  }

  filterForms() {
    if (this.categories && this.categories.length > 0) {
      // 同步篩選 formCategories 和 filteredForms
      this.formCategories = this.allCategories.filter((cat) => this.categories.includes(cat));
      this.filteredForms = this.existingForms.filter((form) =>
        this.categories.includes(form.category)
      );
    } else {
      // 如果未指定 categories，顯示所有
      this.formCategories = this.allCategories;
      this.filteredForms = this.existingForms;
    }
  }

  startFormCreation(category?: string) {
    this.isFormCreationVisible = true;
    if (category) {
      this.formCategories = this.allCategories.filter((cat) => cat === category); // 基於原始列表進行篩選
    } else {
      this.formCategories = this.allCategories; // 顯示所有類別
    }
  }

  saveSelection() {
    if (this.mode === 'existing') {
      console.log('Selected Form ID:', this.selectedFormId);
    } else if (this.mode === 'new') {
      console.log('New Form Details:', {
        name: this.newFormName,
        category: this.selectedCategory,
      });
      this.createFormComponent();
    }
  }

  createFormComponent() {
    if (this.selectedCategory === 'Actions') {
      console.log('Navigate to Actions form creation component');
      // 呼叫 Actions 表單元件的邏輯
    } else if (this.selectedCategory === 'Improvement') {
      console.log('Navigate to Improvement form creation component');
      // 呼叫 Improvement 表單元件的邏輯
    }
  }

  resetForm() {
    this.selectedFormId = null;
    this.newFormName = '';
    this.selectedCategory = ''; // 重置選中的類別
    this.mode = 'existing';
    this.isFormCreationVisible = false;
    this.filterForms(); // 重置類別和表單篩選
  }

  createnew() {
    this.mode = 'new';
    this.selectedCategory = ''; // 確保清空類別選擇
    // 確保 `formCategories` 被正確篩選
    this.filterForms();
  }
}
