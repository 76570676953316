import { Injectable } from '@angular/core';
import { AuthenticationStrategyFactory } from './authentication-strategy-factory';
import { AuthenticationResult } from './authentication-strategy.interface';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  async login(clientId: string, credentials: { username: string; password: string }): Promise<AuthenticationResult> {
    // console.log('LoginService: calling getStrategy with clientId:', clientId);
    const strategy = AuthenticationStrategyFactory.getStrategy(clientId);
    return await strategy.authenticate(credentials);
  }
}
