
{{modulename}}
<table class="table">
  <thead>
    <tr>
      <th>Actions</th>  <!-- 操作列 -->
      <!-- 動態生成欄位標題 -->
      <th *ngFor="let column of getColumns()">{{ column | titlecase }}</th>

    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of data">
      <td>
        <button (click)="editItem(item)">✏️</button>
        <button (click)="deleteItem(item)">🗑️</button>
      </td>
      <!-- 動態生成每一列資料 -->
      <td *ngFor="let column of getColumns()">{{ item[column] }}</td>
    </tr>
  </tbody>
</table>
