import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ThemeService } from '../app/services/theme.service';
import { environment } from '../app/environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  clientId = '';
  name = '';
  css_file_name = '';

  //載入客戶的Theme
  constructor(
    private themeService: ThemeService,
    private titleService: Title
  ) {}

  ngOnInit(): void {

    this.clientId = environment.clientId;
    this.name = environment.companyname;
    this.css_file_name = environment.css_file_name;


     //載入客戶的Theme
     this.themeService.loadClientTheme(this.css_file_name);
    //  this.titleService.setTitle(this.name);


  }
}
