<div class="pdf-helper">
  <h1>PDF Helper</h1>
  <div>
    <label for="pdfUpload">Upload PDF File：</label>
    <input
      type="file"
      id="pdfUpload"
      (change)="onFileSelected($event)"
      accept="application/pdf"
    />
  </div>
  <div>
    <button (click)="convertPDF(true)">1.Convert to Word</button>
    <button style="margin-left: 10px;" (click)="sendToAI()">2.Send To AI Model</button>
    <!-- <button (click)="convertPDF(false)">Convert to Image</button> -->
  </div>

  <!-- //顯示所有頁面與狀態 -->
  <div>
    <a *ngFor="let content of textContent" style="cursor: pointer;"
       [ngStyle]="{'color': content.status === 'Success' ? 'green' : content.status === 'Fail' ? 'red' : 'black'}"
       (click)="reloaddata(content.page)">
       <!-- (click)="content.status === 'Fail' ? reloaddata(content.page) : null"> -->
      {{ content.page }}
    </a>
  </div>

  <div>
    <span *ngIf="totalpage != 0">Total Pages: {{ totalpage }}</span><br>
    <span *ngIf="totalword != 0">Total Words: {{ totalword }}</span>
  </div>

  <div *ngIf="jsonresult.length > 0 ">
    <div *ngIf="!this.isLoading">全部執行完成</div>
    <button (click)="downloadJson()">3.Download Json</button>
  </div>


  <div *ngIf="textContent.length > 0">
    <h2>Word Content</h2>
    <div *ngFor="let content of textContent">
      <div>
        <strong>Page {{ content.page }}:</strong>
        <pre [innerHTML]="content.data"></pre> <!-- 顯示該頁的文字內容 -->
      </div>

      <div *ngIf="content.AIRespnese">
        <strong>AI Response:</strong>
        <pre>{{ content.AIRespnese }}</pre> <!-- 顯示該頁的 AI 回應 -->
      </div>

    </div>
  </div>

  <div *ngIf="jsonresult.length > 0 ">
    <h2>下載Json檔</h2>
  <pre style="background-color: lightgray;">{{ AIResult}}</pre> <!-- 顯示格式化後的 JSON 字符串 -->
</div>

  <div *ngIf="imageContent.length > 0">
    <h2>Images</h2>
    <ul>
      <li *ngFor="let content of imageContent">
        Page: {{ content.page }}
        <a [href]="content.data" target="_blank" rel="noopener noreferrer">
          (Link)
        </a>
      </li>
    </ul>
  </div>



</div>
