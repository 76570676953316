import { Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { marked } from 'marked';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-markdown-viewer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './markdown-viewer.component.html',
  styleUrls: ['./markdown-viewer.component.scss']
})
export class MarkdownViewerComponent implements OnChanges {

  @Input() markdownContent: string = ''; // 從父組件接收的 Markdown 內容
  htmlContent: SafeHtml = ''; // 解析後的 HTML

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(): void {
    this.renderMarkdown();
  }

  // 渲染 Markdown 內容為 HTML
  async renderMarkdown(): Promise<void> {
    if (this.markdownContent.trim()) {
      try {
        // 使用 marked 轉換 Markdown 到 HTML
        const rawHtml = await Promise.resolve(marked(this.markdownContent));

        // 將轉換後的 HTML 標記為安全，避免 XSS 攻擊
        this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(rawHtml);
      } catch (error) {
        console.error('Error rendering Markdown:', error);
        this.htmlContent = 'Error rendering Markdown';
      }
    } else {
      this.htmlContent = '';
    }
  }
}
