
import { Component, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common'; // Import CommonModule

@Component({
  selector: 'app-forklift-checklist-byday',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './forklift-checklist-byday.component.html',
  styleUrl: './forklift-checklist-byday.component.scss'
})
export class ForkliftChecklistBydayComponent {
  @Output() valueSubmitted = new EventEmitter<any>();

  formfinished = false;

  // 檢查項目
  checkItems = [
    { title: '項次1: 機油狀況是否正常?', checked: '', remarks: '', name: 'item1' },
    { title: '項次2: 水箱狀況是否正常?', checked: '', remarks: '', name: 'item2' },
    { title: '項次3: 機架狀況是否正常?', checked: '', remarks: '', name: 'item3' },
    { title: '項次4: 輪胎狀況是否正常?', checked: '', remarks: '', name: 'item4' },
    { title: '項次5: 煞車系統是否正常?', checked: '', remarks: '', name: 'item5' },
    { title: '項次6: 操作手把是否靈活?', checked: '', remarks: '', name: 'item6' },
    { title: '項次7: 引擎啟動是否正常?', checked: '', remarks: '', name: 'item7' },
    { title: '項次8: 燈光是否正常運作?', checked: '', remarks: '', name: 'item8' },
    { title: '項次9: 超越限制的指示是否正常?', checked: '', remarks: '', name: 'item9' },
    { title: '項次10: 駕駛視野是否良好?', checked: '', remarks: '', name: 'item10' },
    { title: '項次11: 載重指示是否正常?', checked: '', remarks: '', name: 'item11' },
    { title: '項次12: 輪胎壓力是否正常?', checked: '', remarks: '', name: 'item12' },
    { title: '項次13: 傳動帶狀況是否正常?', checked: '', remarks: '', name: 'item13' },
    { title: '項次14: 機器外觀是否整潔?', checked: '', remarks: '', name: 'item14' },
    { title: '項次15: 安全帶是否正常運作?', checked: '', remarks: '', name: 'item15' },
    { title: '項次16: 清掃坡道是否平整?', checked: '', remarks: '', name: 'item16' },
    { title: '項次17: 反向鏡是否正常?', checked: '', remarks: '', name: 'item17' },
    { title: '項次18: 駕駛座是否堅固?', checked: '', remarks: '', name: 'item18' },
    { title: '項次19: 噪音是否正常?', checked: '', remarks: '', name: 'item19' },
    { title: '項次20: 燃料是否充足?', checked: '', remarks: '', name: 'item20' },
    { title: '項次21: 其他（請注明）?', checked: '', remarks: '', name: 'item21' },
  ];

  constructor() {
    // 初始化或其他設置
  }

  // 提交表單
  sendValue() {
    const submittedData = this.checkItems.map(item => ({
      title: item.title,
      checked: item.checked,
      remarks: item.remarks,
    }));

    this.valueSubmitted.emit(submittedData);
    this.formfinished = true;
  }
}
