import { Component, AfterViewInit } from '@angular/core';
import * as echarts from 'echarts'; // 引入 ECharts 库

@Component({
  selector: 'app-line-chart',
  standalone: true,
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements AfterViewInit {
  data = [
    { month: 'Jan', audits: 10, issues: 5 },
    { month: 'Feb', audits: 15, issues: 8 },
    { month: 'Mar', audits: 12, issues: 6 },
    { month: 'Apr', audits: 8, issues: 3 },
    { month: 'May', audits: 20, issues: 10 },
    { month: 'Jun', audits: 14, issues: 7 },
    { month: 'Jul', audits: 18, issues: 9 },
    { month: 'Aug', audits: 16, issues: 8 },
    { month: 'Sep', audits: 22, issues: 11 },
    { month: 'Oct', audits: 25, issues: 15 },
    { month: 'Nov', audits: 28, issues: 17 },
    { month: 'Dec', audits: 30, issues: 20 },
  ];

  constructor() {}

  ngAfterViewInit(): void {
    this.createChart();
  }

  createChart() {
    const chartDom = document.getElementById('lineChart')!;
    const myChart = echarts.init(chartDom);

    const months = this.data.map(d => d.month);
    const audits = this.data.map(d => d.audits);
    const issues = this.data.map(d => d.issues);

    const option = {
      title: {
        text: '稽核次数与缺失总数',
      },
      tooltip: {
        trigger: 'axis',
      },
      legend: {
        data: ['Audits', 'Issues'],
      },
      xAxis: {
        type: 'category',
        data: months,
      },
      yAxis: [
        {
          type: 'value',
          name: 'Audits',
          position: 'left',
          axisLine: {
            lineStyle: {
              color: 'green',
            },
          },
        },
        {
          type: 'value',
          name: 'Issues',
          position: 'right',
          axisLine: {
            lineStyle: {
              color: 'red',
            },
          },
        },
      ],
      series: [
        {
          name: 'Audits',
          type: 'line',
          data: audits,
          color: 'green',
        },
        {
          name: 'Issues',
          type: 'line',
          data: issues,
          color: 'red',
          yAxisIndex: 1, // 使用第二个 Y 轴
        },
      ],
    };

    myChart.setOption(option);
  }
}
