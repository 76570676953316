import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';  // 這是作為服務引入，不要放入 imports
import { CommonModule } from '@angular/common';  // 引入 CommonModule
import { RouterModule } from '@angular/router';  // 引入 RouterModule
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-layout-center',
  standalone: true,  // 標明這是一個 standalone 組件
  templateUrl: './layout-center.component.html',
  styleUrls: ['./layout-center.component.scss'],
  imports: [CommonModule,RouterModule]  // 只需導入模組，不需要導入服務
})
export class LayoutCenterComponent implements OnInit {

  logoUrl: string = "";
  name: string = "";

  constructor(
    private route: ActivatedRoute,  // 這裡保持依賴注入的 ActivatedRoute 服務
  ) {}

  ngOnInit() {

    this.logoUrl = environment.logoUrl || '';  // 確保有值才設置
    this.name = environment.companyname || '';

  }
}
