<div class="container min-vh-100 chat-main-container" style="border: 1px solid lightgray">
  <div class="row">
    <!-- 左側空白區塊，僅在大螢幕顯示 -->
    <div class="col-1 d-none d-lg-block"></div>

    <!-- 中間內容 -->
    <div class="col-12 col-lg-10">
      <div class="logo-container">
        <!-- <img src="path-to-logo.png" class="q-logo" alt="Logo"> -->
      </div>
      <router-outlet></router-outlet>
    </div>

    <!-- 右側空白區塊，僅在大螢幕顯示 -->
    <div class="col-1 d-none d-lg-block"></div>
  </div>
</div>
