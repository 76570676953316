import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OpanaiService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  public chatwithgpts(message: string,imageAttachments:string[] = [],threadid:string="",AImodel = "") {
    return this.http.post<any>(`${this.apiUrl}/api/gpts/chat`, { message,imageAttachments,threadid,AImodel });
  }

  public chatwithassistant(message: string,assitantid:string,threadid:string="",imageAttachments:string[] = []) {

    return this.http.post<any>(`${this.apiUrl}/api/assitant/chat`, { message,assitantid,threadid ,imageAttachments});
  }

  public chatWithAssistantSSE(userMessage: string, assistantId: string): Observable<string> {
    return new Observable<string>((observer) => {

      let apiurl = `${this.apiUrl}/api/assitant/chatwithassistant?message=${userMessage}&assistantid=${assistantId}`;

      console.log('apiurl:', apiurl);

      const eventSource = new EventSource(apiurl);

      eventSource.onmessage = (event) => {
        const reply = event.data;

        if (reply === '[DONE]') {
          eventSource.close(); // 關閉連接
          observer.complete();  // 完成 Observable
        } else {
          observer.next(reply);  // 發送新訊息給訂閱者
        }
      };

      eventSource.onerror = (error) => {
        observer.error(error);  // 錯誤處理
      };

      // 當 Observable 被取消訂閱時關閉 SSE 連接
      return () => {
        eventSource.close();
      };
    });
  }

  public getfilelist(vector_store_id: string) {
    return this.http.post<any>(`${this.apiUrl}/api/vectordb/getfilelist`, { vector_store_id });
  }

  public deletefile(vector_store_id: string,id:string) {
    return this.http.post<any>(`${this.apiUrl}/api/vectordb/deletefile`, { vector_store_id,id });
  }

  public uploadFiles(files: File[], vector_store_id: string): Observable<any> {
    const formData = new FormData();

    // 將檔案添加到 formData
    files.forEach((file) => {
      formData.append('files', file);
    });

    // 添加 vector_store_id 到 formData
    formData.append('vector_store_id', vector_store_id);

    // 發送 POST 請求，返回 Observable，並監控上傳進度
    return this.http.post<any>(`${this.apiUrl}/api/vectordb/upload`, formData, {
      headers: new HttpHeaders({
        'Accept': 'application/json',
      }),
      reportProgress: true, // 設置為 true 以獲取進度事件
      observe: 'events', // 觀察所有事件
    });
  }

}
