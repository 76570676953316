<div class="form-linker">
  <button type="button" *ngIf="!isFormCreationVisible" (click)="startFormCreation()">
    Link or Create a New Form
  </button>

  <div *ngIf="isFormCreationVisible">
    <h4>Select an Existing Form</h4>
    <select [(ngModel)]="selectedFormId">
      <option *ngFor="let form of filteredForms" [value]="form.id">
        {{ form.name }} ({{ form.category }})
      </option>
    </select>

    <a class="creatlink" (click)="createnew()">Create New One</a>

    <div *ngIf="mode === 'new'">
      <label>Form Category:</label>
      <select [(ngModel)]="selectedCategory">
        <option *ngFor="let category of formCategories" [value]="category">
          {{ category }}
        </option>
      </select>
      <label>Form Name:</label>
      <input type="text" [(ngModel)]="newFormName" />
    </div>

    <div class="buttonset">
      <button type="button" (click)="saveSelection()">Save</button>
      <button type="button" (click)="resetForm()">Reset</button>
    </div>
  </div>
</div>
