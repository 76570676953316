import { ConfigurationService } from './../../services/configuration.service';
import { Component, CSP_NONCE, OnInit, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataService, AssistantDataItem } from '../../services/data.service';
import { environment } from '../../environments/environment';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-lobby',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './lobby.component.html',
  styleUrl: './lobby.component.scss',
})
export class LobbyComponent implements OnInit {
  AssistantDataItem: AssistantDataItem[] = [];
  username = '';
  modules = '';

  lobbyinfotext = '';

  constructor(
    private dataService: DataService,
    private router: Router, // 注入 Router
    private renderer: Renderer2
  ) {}

  companyname = environment.companyname;

  ngOnInit() {
    //取得存在LocalStorage的authToken
    this.username = localStorage.getItem('username') || '';
    this.modules = localStorage.getItem('modules') || '';

    //將字串轉成陣列
    const modulesArray = JSON.parse(this.modules);
    //  console.log('modulesArray', modulesArray);

    this.dataService.getLobbyInfoText(this.companyname).subscribe((items) => {
      this.lobbyinfotext = items;
    });

    // 使用 DataService 取得資料
    this.dataService.getLobbyDataItems(this.companyname).subscribe((items) => {

      if (modulesArray.length == 1 && modulesArray[0] == 'ALL') {
        this.AssistantDataItem = items;
      } else {
        this.AssistantDataItem = items.filter((item) => {
          return modulesArray.includes(item.title);
        });
      }
    });

    //使用有權限的modules來設定顯示的功能
  }

  gotoAI(assistantid: string) {
    //另開分頁到AI助手
    this.router.navigate(['/assistant'], {
      queryParams: { id: assistantid },
    });
  }
}
