<div class="uploader-container">
  <h2>題目上傳與修正</h2>

  <div>
  <!-- 檔案上傳 -->
  <input type="file" (change)="onFileSelected($event)" accept=".json" />
</div>

<div class="button-set" style="margin-top: 10px;">
  <!-- 上傳按鈕 -->
  <button (click)="handleFileUpload()">修正格式</button>
  <!-- 下載修正後的檔案 -->
  <button (click)="downloadFixedFile()">下載修正後的檔案</button>
</div>

  <!-- 顯示上傳狀態 -->
  <pre>{{ uploadStatus }}</pre>

</div>
