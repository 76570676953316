import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DatabaseService } from '../../../services/database.service';  // 引入 DatabaseService


@Component({
  selector: 'app-showlist',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './showlist.component.html',
  styleUrls: ['./showlist.component.scss']
})
export class ShowlistComponent {

  // 用來接收父元件傳入的資料
 // 假資料，模擬從 Azure Cosmos DB 獲得的資料
 @Input() data: any[] = [];
 @Input() modulename:string = '';

constructor(private databaseService: DatabaseService) {}

 // 在元件初始化時，呼叫 getDataByQuery 來獲取資料
ngOnInit(): void {
    const filter = {};  // 設定你的過濾條件，根據需要調整
    const pageSize = 10;  // 設定每頁大小
    const continuationToken = '';  // 如果有分頁，可以提供 continuationToken

    // 使用 Observer 物件來訂閱 Observable
    this.databaseService.getDataByQuery(filter, pageSize, continuationToken)
      .subscribe({
        next: (result) => {
          this.data = result.items;  // 假設返回的資料中有 items 屬性
          console.log('Received data:', this.data);
        },
        error: (error) => {
          console.error('Error fetching data:', error);
        },
        complete: () => {
          console.log('Data fetching complete');
        }
      });
  }


  // 獲取欄位名稱，會排除不需要顯示的欄位
  getColumns(): string[] {
    if (this.data && this.data.length > 0) {
      const firstItem = this.data[0];
      return Object.keys(firstItem).filter(column =>
        !['id', '_rid', '_self', '_etag', '_attachments', '_ts'].includes(column)
      );
    }
    return [];
  }

  // 編輯功能
  editItem(item: any) {
    console.log('Edit item:', item);
    // 在這裡可以處理編輯邏輯
  }

  // 刪除功能
  deleteItem(item: any) {
    console.log('Delete item:', item);
    // 在這裡可以處理刪除邏輯
  }
}
