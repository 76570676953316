import { Injectable } from '@angular/core';
import { BlobServiceClient } from '@azure/storage-blob';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AzureBlobService {

  private containerName = environment.AzureBlob.PublicContainerName;
  private blobsasToken = environment.AzureBlob.blobsasToken;
  private blobUrl = environment.AzureBlob.blobUrl;

  constructor() {}

  async uploadFile(file: File): Promise<string> {

    // console.log('containerName', this.containerName);
    // console.log('blobsasToken', this.blobsasToken);
    // console.log('blobUrl', this.blobUrl);

    try {
      const blobServiceClient = new BlobServiceClient(`${this.blobUrl}?${this.blobsasToken}`);
      const containerClient = blobServiceClient.getContainerClient(this.containerName);
      //這邊在設定檔名
      const blobClient = containerClient.getBlockBlobClient(file.name);

      // 使用新的 uploadData 方法上傳檔案
      await blobClient.uploadData(file);

      // 檔案上傳後的公開 URL
      const fileUrl = this.removeQueryStringBySV(blobClient.url);

      console.log('File uploaded to Azure Blob Storage:', fileUrl);

      return fileUrl;
    } catch (error) {
      console.error('Error uploading file to Azure Blob Storage:', error);
      throw error;
    }
  }

  async uploadFileByBlob(blob: Blob, filename: string): Promise<string> {
    try {
      const blobServiceClient = new BlobServiceClient(`${this.blobUrl}?${this.blobsasToken}`);
      const containerClient = blobServiceClient.getContainerClient(this.containerName);
      //這邊在設定檔名
      const blobClient = containerClient.getBlockBlobClient(filename);

      // 使用新的 uploadData 方法上傳 Blob
      await blobClient.uploadData(blob);

      // 檔案上傳後的公開 URL
      const fileUrl = this.removeQueryStringBySV(blobClient.url);

      console.log('Blob uploaded to Azure Blob Storage:', fileUrl);

      return fileUrl;
    } catch (error) {
      console.error('Error uploading blob to Azure Blob Storage:', error);
      throw error;
    }
  }


  removeQueryStringBySV(url: string): string {
    return url.split('?sv')[0];
  }

}


