import { DemoformComponent } from '../conponents/form/demoform/demoform.component';
import { Demoform2Component } from '../conponents/form/demoform2/demoform2.component';
import { NonConformanceComponent } from '../conponents/form/non-conformances/non-conformances.component';
import { ActionsComponent } from '../conponents/form/actions/actions.component';

import { SimulateExamComponent } from '../conponents/exam/simulate-exam/simulate-exam.component';
import { QuestionsFormatterComponent } from './exam/questions-formatter/questions-formatter.component';

import { ShowlistComponent } from '../conponents/list/showlist/showlist.component';
import {LineChartComponent} from '../conponents/chart/line-chart/line-chart.component';
import {ForkliftChecklistBydayComponent} from '../conponents/checklist/forklift-checklist-byday/forklift-checklist-byday.component';

import { FiledownloaderComponent } from '../conponents/tools/filedownloader/filedownloader.component';
import { PdfHelperComponent } from '../conponents/tools/pdf-helper/pdf-helper.component';



export const COMPONENT_MAP: { [key: string]: any } = {
  demoformcomponent: DemoformComponent,
  demoform2component: Demoform2Component,
  filedownloader: FiledownloaderComponent,
  simulateExamComponent: SimulateExamComponent,
  nonConformanceComponent: NonConformanceComponent,
  showlistComponent: ShowlistComponent,
  lineChartComponent: LineChartComponent,
  forkliftChecklistBydayComponent: ForkliftChecklistBydayComponent,
  pdfHelperComponent: PdfHelperComponent,
  questionsFormatterComponent: QuestionsFormatterComponent,
  actionsComponent: ActionsComponent,
};
